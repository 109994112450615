<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
    <v-snackbar right v-model="$store.state.snackbar.show" :timeout="$store.state.snackbar.timeout" :color="$store.state.snackbar.color">
      {{ $store.state.snackbar.text }}
      <v-btn icon @click="$store.state.snackbar.show = false">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
    mounted() {
      this.loadPerfil()
    }
  }
</script>